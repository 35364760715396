import React from "react"

import useImages from "../hooks/useImages"
import useViewportSize, { SIZES } from "../hooks/useViewportSize"

import Layout from "../components/layout"
import Section from "../components/section"
import Parallax from "../components/parallax"
import Separator from "../components/separator"
import Playlist from "../components/playlist"

const { XS } = SIZES

export default function RSVP() {
  const { hero, rsvpForm } = useImages()
  const viewportSize = useViewportSize()
  const heroContent = <></>

  return (
    <Layout
      heroImg={hero.childImageSharp}
      heroContent={heroContent}
      heroHeight={viewportSize === XS ? "100px" : "250px"}
    >
      <Section className={"text-block"}>
        <h2>Regalos</h2>
        <Separator />
        <p>
          Contar con vuestra presencia y más tras este último año y medio ya es un gran regalo para nosotros. 
          Si aún así queréis ayudarnos con la boda y nuestro viaje os facilitamos nuestro número de cuenta. 
        </p>
        <p>
          Muchas gracias y nos vemos pronto.
        </p>
      </Section>
      <Section>
        <Parallax img={rsvpForm.childImageSharp} speed={0} />

        <div style={{
          maxWidth: '450px',
          backgroundColor: 'rgba(255, 255, 255, 0.9)',
          padding: '4rem',
          margin: '5rem auto',
          minHeight: '300px'
        }}>
          <h2>ES69 2100 4801 7421 0028 5906</h2>
          <br/>
          <br/>
          <br/>
          <br/>
          <h3>Titulares Pablo García y Arancha Palacio</h3>
          <p>
            No olvidéis poner vuestro nombre en el concepto.
          </p>
        </div>
      </Section>
      <Playlist />
    </Layout>
  )
}
